<template>
    <div class="post-detail" @scroll="onScroll">
        <StackRouterHeaderBar :class="{ 'show-title': showTitle }" :title="post.title" :showTitle="showTitle" />
        <div class="p-16">
            <div class="title-time">
                <div class="title f-medium f-20 c-black" v-html="post.title" />
                <span class="time c-grey-06 f-13" v-html="postedAt" />
            </div>
            <div class="hits f-13 c-grey-06 m-b-20" v-html="`조회수 ${post.view_count.toLocaleString()}`" />
        </div>
        <div class="host-profile f-13" :class="{ 'no-photo': !isPhoto }">
            <div class="age-gender m-r-8" :class="[post.gender === 0 ? 'male' : 'female']">
                <div class="icon-wrapper">
                    <img :src="require('@/assets/images/person.svg')" alt="" />
                </div>
            </div>
            <div class="verified c-grey-06" v-if="verificationString">
                <span class="f-medium" v-html="verificationString" />
                <i class="material-icons f-16 c-grey-06">admin_panel_settings</i>
            </div>
        </div>
        <div class="content" :class="{ 'more-margin': !isMe }">
            <div class="photo-wrapper" v-if="post.photo_url">
                <img :src="post.photo_url" alt="" />
            </div>
            <p class="content-text" :class="{ 'no-photo': !isPhoto }" v-html="content" />
        </div>
        <LoungeRequestCards v-if="isMe" :requests="requests" />
        <BottomButton :disabled="disabled || isSameGender" :label="label" v-else @click="sendRequest" />
    </div>
</template>

<script>
import loungeService from '@/services/lounge'
import LoungeRequestCards from '@/routes/lounge/components/LoungeRequestCards'
export default {
    name: 'LoungePostDetailPage',
    props: {
        post: Object,
    },
    async mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_LoungeDetailPage',
            },
        })

        if (this.from === 'interested') {
            const {
                host_checked: hostChecked,
                participant_checked: participantChecked,
                id,
                lounge_id,
            } = this.post.my_request
            if (hostChecked === true && participantChecked === false) {
                try {
                    await loungeService.participantCheck(lounge_id, id)
                    this.$store.commit('updateReadInterest', lounge_id)
                } catch (e) {
                    this.$toast.error(e.data)
                }
            }
        }
    },
    data: () => ({
        showTitle: false,
    }),
    components: {
        LoungeRequestCards,
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        isMe() {
            return this.$store.getters.me.id === this.post.user_id
        },
        isPhoto() {
            return this.post.photo_url
        },
        requests() {
            return this.post.requests || []
        },
        postedAt() {
            const days = this.$moment().diff(this.post.created_at, 'days')
            const hours = this.$moment().diff(this.post.created_at, 'hours')
            const mins = this.$moment().diff(this.post.created_at, 'minutes')

            if (days) {
                return `${days}일 전`
            } else if (hours) {
                return `${hours}시간 전`
            } else {
                return `${mins}분 전`
            }
        },
        content() {
            return this.post.content.split(/\n/).join('<br>')
        },
        label() {
            if (this.isSameGender) return '동성 회원에게 신청할 수 없어요'

            if (this.post.my_request) return '신청완료'

            return `신청하기`
        },
        isSameGender() {
            return this.post.gender === this.me.gender
        },
        disabled() {
            return !!this.post.my_request
        },
        verificationString() {
            const badges = this.post.verification_badges
            if (!badges.length) return false

            let word = ''
            badges.forEach((badge, idx) => {
                word += this.$translate(badge.verification.toUpperCase())
                word += idx !== badges.length - 1 ? ',' : ''
            })

            return `<span class='f-bold' style='text-decoration: underline'>${word}</span> 인증 완료`
        },
    },
    methods: {
        async sendRequest() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Click_Lounge_Detail_CTA',
                },
            })

            if (!this.$isVerifiedUser()) {
                this.$modal
                    .basic({
                        body: '프로필 등록 완료 후 신청하실 수 있습니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(() => {
                        this.$stackRouter.pop()
                        this.$goProfileRegister()
                    })
                return
            }

            try {
                const idx = await this.$modal.custom({
                    component: 'ModalLoungeRequest',
                    options: {
                        title: '신청하기',
                        body: '글쓴이에게 회원님의 프로필을 보냅니다.<br>글쓴이가 수락할 경우, 대화 나눌 수 있어요!',
                    },
                })
                if (idx === null || idx === undefined) return

                if (idx === 0) {
                    const myHeart = (this.$store.getters.badges || {}).heart
                    const useHeart = (this.$store.getters.settings || {}).heart.lounge_request

                    const i = await this.$modal.basic({
                        title: '신청하기',
                        body: `글쓴이에게 내 프로필을 보냅니다.<br>글쓴이가 무료로 프로필을 확인하기 때문에 수락률이 높습니다<br>하트 ${useHeart}개를 사용합니다.`,
                        buttons: [
                            {
                                label: '취소',
                                class: 'btn-default',
                            },
                            {
                                label: '신청하기',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (!i) return
                    else {
                        if (myHeart < useHeart) {
                            this.$modal
                                .basic({
                                    body: `하트가 ${useHeart - myHeart}개가 부족합니다. 하트를 충전해 주세요!`,
                                    buttons: [
                                        {
                                            label: '취소',
                                            class: 'btn-default',
                                        },
                                        {
                                            label: '충전하기',
                                            class: 'btn-primary',
                                        },
                                    ],
                                })
                                .then(idx => {
                                    if (idx === 1) {
                                        this.$stackRouter.push({ name: 'InAppStorePage', props: { from: 'alert' } })
                                    }
                                })
                            return
                        }
                    }
                } else if (idx === 1) {
                    const i = await this.$modal.basic({
                        title: '신청하기',
                        body: `글쓴이에게 내 프로필을 보냅니다.<br>글쓴이가 하트를 사용해서 프로필을 확인하기 때문에 수락률이 낮을 수 있습니다.`,
                        buttons: [
                            {
                                label: '취소',
                                class: 'btn-default',
                            },
                            {
                                label: '신청하기',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (!i) return
                }
                const { lounge_request: request, msg } = await loungeService.request(this.post.id, { paid: idx === 0 })
                this.$store.commit('updateRequestPost', { id: this.post.id, request: request })
                this.$toast.success(msg)
                this.$store.dispatch('loadBadges')
            } catch (e) {
                console.log(e)
            }
        },
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 50
        },
    },
}
</script>

<style scoped lang="scss">
.post-detail {
    .p-16 {
        padding: 16px 16px 0 16px !important;
    }
    .title-time {
        margin-bottom: 8px;
        @include between;
        align-items: unset;

        span.time {
            white-space: nowrap;
            margin-left: 16px;
        }
    }
    .host-profile {
        display: flex;
        align-items: center;
        padding: 0 16px 26px 16px;

        &.no-photo {
            border-bottom: 1px solid $grey-02;
        }
    }
    .age-gender {
        &.male {
            color: $blue-man;
            .icon-wrapper {
                @extend .icon-wrapper-default;
                background: $blue-man;
            }
        }
        &.female {
            color: $pink-woman;
            .icon-wrapper {
                @extend .icon-wrapper-default;
                background: $pink-woman;
            }
        }
    }
    .verified {
        display: flex;
        align-items: center;
    }
    .icon-wrapper-default {
        width: 22px;
        height: 22px;
        border-radius: 9px;
        @include center;
    }
    .content {
        img {
            width: 100vw;
            height: 100vw;
        }
        .content-text {
            padding: 32px 16px;
            color: black;

            //&.no-photo {
            //    padding: 0 16px 32px 16px;
            //}
        }
        &.more-margin {
            margin-bottom: 100px;
        }
    }
}
</style>
