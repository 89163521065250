<template>
    <div class="request-all">
        <div class="request-header f-medium m-b-28">
            <div class="c-black f-18" v-html="`신청한 회원`" />
            <div class="cnt f-12 c-black" v-html="`${requests.length}명 신청 중`" />
        </div>
        <div class="empty c-grey-06 f-14" v-if="!requests.length" v-html="$translate('EMPTY_REQUESTS')" />
        <div class="not-empty" v-else>
            <div
                class="f-16"
                :class="[idx === 0 ? 'paid' : 'not-paid']"
                v-for="(cards, idx) in [requestPaid, requestFree]"
                :key="idx"
                v-show="cards.length"
            >
                <div class="toast-absolute-box">
                    <div class="header">
                        <span>
                            <span class="f-bold"> {{ idx === 0 ? '무료' : '유료' }}로 </span>프로필 확인
                        </span>
                        <i class="material-icons" @click="onToast(idx)">info</i>
                    </div>
                    <transition name="fade">
                        <div class="toast-container" v-if="idx === 0 ? freeToastState : paidToastState">
                            <div class="toast" v-html="$translate(`LOUNGE_CARD_OPEN_TOAST_${idx}`)" />
                        </div>
                    </transition>
                </div>
                <div class="cards grid-col">
                    <LoungeCard v-for="card in cards" :key="card.id" :card="card" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoungeCard from '@/routes/lounge/components/LoungeCard'

export default {
    name: 'LoungeRequestCards',
    data: () => ({
        freeToastState: false,
        paidToastState: false,
    }),
    props: {
        requests: Array,
    },
    components: {
        LoungeCard,
    },
    computed: {
        requestFree() {
            return (this.requests || []).filter(r => !r.paid_id)
        },
        requestPaid() {
            return (this.requests || []).filter(r => r.paid_id)
        },
    },
    methods: {
        async onClickCard(card) {},

        onToast(idx) {
            if (idx) {
                // 유료
                this.paidToastState = true
                setTimeout(() => {
                    this.paidToastState = false
                }, 2000)
            } else {
                this.freeToastState = true
                setTimeout(() => {
                    this.freeToastState = false
                }, 2000)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.request-all {
    padding: 32px 16px;
    border-top: 1px solid $grey-02;

    .empty,
    .not-empty {
        margin-bottom: 50px;
    }

    .request-header {
        display: flex;
        align-items: center;

        .cnt {
            background: $grey-02;
            border-radius: 6px;
            padding: 1px 8px;
            margin-left: 8px;
        }
    }

    .toast-absolute-box {
        margin-bottom: 20px;
        position: relative;
        z-index: 5;

        .header {
            display: flex;
            align-items: center;
            color: black;

            i {
                font-size: 15px;
                color: $grey-06;
                margin-left: 6px;
            }
        }
        .toast {
            opacity: 0.96;
            width: 100%;
            background-color: $grey-09;
            border-radius: 12px;
            color: white;
            font-size: 14px;
            position: absolute;
            padding: 12px;
            margin-top: 8px;
            z-index: 3000;
        }
    }
    .paid {
        margin-bottom: 40px;
    }
}
</style>
