<template>
    <div class="card" :class="{ 'card-flipped': onFlip }">
        <div class="card-inner">
            <div class="profile" v-if="showCard" @click="goProfileDetail">
                <div class="img-wrapper">
                    <img v-lazy="src" alt="" />
                </div>
            </div>
            <div class="unzipped" v-else @click.stop="sendFlipRequest">
                <div class="unzipped-inner">
                    <img v-lazy="require('@/assets/images/heart-logo.png')" alt="" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import loungeService from '@/services/lounge'

export default {
    name: 'LoungeCard',
    data: () => ({
        status: '',
        onFlip: false,
    }),
    props: {
        card: Object,
    },
    computed: {
        showCard() {
            return !(this.card.accept_status === 'request')
        },
        src() {
            if (this.card.photos.length) return this.card.photos[0]

            return this.$alternativeImg(this.card.gender)
        },
    },
    methods: {
        async sendFlipRequest() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Click_Lounge_Detail_Card',
                },
            })

            if (!this.card.paid_id) {
                const myHeart = (this.$store.getters.badges || {}).heart
                const useHeart = (this.$store.getters.settings || {}).heart.lounge_request_open
                const idx = await this.$modal.basic({
                    title: '프로필 확인하기',
                    body: `하트 ${useHeart}개를 사용해 프로필을 확인합니다. `,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (!idx) return
                else {
                    if (myHeart < useHeart) {
                        this.$modal
                            .basic({
                                body: `하트가 ${useHeart - myHeart}개가 부족합니다. 하트를 충전해 주세요!`,
                                buttons: [
                                    {
                                        label: '취소',
                                        class: 'btn-default',
                                    },
                                    {
                                        label: '충전하기',
                                        class: 'btn-primary',
                                    },
                                ],
                            })
                            .then(idx => {
                                if (idx === 1) {
                                    this.$stackRouter.push({ name: 'InAppStorePage', props: { from: 'alert' } })
                                }
                            })
                        return
                    }
                }
            }
            await loungeService.check(this.card.lounge_id, this.card.id)
            this.$store.dispatch('loadBadges')
            this.$store.commit('updateCardStatus', this.card)

            setTimeout(() => {
                this.status = 'checked'
                this.onFlip = false
            }, 100)
        },
        goProfileDetail() {
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.card.user_id,
                    from: 'LoungeCard',
                    loungePayloads: {
                        loungeId: this.card.lounge_id,
                        loungeRequestId: this.card.id,
                        card: this.card,
                        chatId: this.card.chat_id || null,
                    },
                },
            })
        },
        initStatus() {
            this.status = this.card.accept_status
            this.chatId = this.card.chat_id || null
        },
    },
    mounted() {
        this.initStatus()
    },
}
</script>

<style scoped lang="scss">
$column-gap: 12px;
$side-padding: 16px;
$card-width: calc((100vw - #{$side-padding} * 2 - #{$column-gap}) / 2);
$card-height: calc(#{$card-width} * 1.27);
.card,
.card-inner {
    width: $card-width;
    height: $card-width;
    background-color: transparent;
    border: none;
}

.card {
    perspective: 2000px;
    transition: transform 1s;
    box-shadow: none;

    &.card-flipped {
        .card-inner {
            transform: rotateY(180deg);
        }
    }
    .profile {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .unzipped {
        background: $grey-09;
        padding: 4px;
        border-radius: 12px;
        @extend .profile;
        @include center;

        .unzipped-inner {
            background: $grey-09;
            border-radius: 9px;
            border: 1px solid $purple-primary;
            @extend .profile;
            @include center;

            img {
                width: 52px;
                height: 52px;
            }
        }
    }
}
</style>
